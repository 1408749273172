import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  ButtonToolbar,
  CheckPicker,
  Checkbox,
  IconButton,
  Panel,
  Table,
  Tag,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, {
  ActivityChart,
  ContentState,
  DelButton,
  HeaderSearch,
  Help,
  TableFooter,
  alert,
  hasErrors,
  setTitle,
} from 'content'
import { filterStatusValues, statusFilter } from 'content/statuspage'
import moment from 'moment-timezone'
import PageTable, { Sort } from 'pagetable'
import {
  FaBraille,
  FaHeartbeat,
  FaPen,
  FaServer,
  FaSort,
  FaSortDown,
  FaSortUp,
} from 'react-icons/fa'
import ROUTES from 'routes'
import RTMIP, {
  ActivityData,
  ArchiveOpt,
  Camera,
  Scheme,
  ShortModel,
  StatusModel,
} from 'rtmip'
import * as STATUS from 'status'

interface Props extends WithTranslation {}

interface State extends ContentState {
  cameras: Camera[]
  selected: Record<number, Camera>
  activity: Record<number, ActivityData>

  filter: Record<string, any>
  filterValues: {
    status: ShortModel[]
    sectors: ShortModel[]
    schemes: Scheme[]
    types: ShortModel[]
  }

  cols: {
    analytics: boolean
    desc: boolean
    sector: boolean
  }

  sort: Sort
}

class Cameras extends React.Component<Props, State> {
  state = {
    selected: {},
    filter: {},
    filterValues: {
      status: filterStatusValues,
      schemes: [] as Scheme[],
      sectors: [] as ShortModel[],
      types: [] as ShortModel[],
    },
    cols: {
      analytics: false,
      desc: false,
      sector: false,
    },
    sort: { col: 'status', type: 'desc' },
  } as State

  interval = 0

  componentDidMount() {
    setTitle('cameras.title')

    const q = new URLSearchParams(window.location.search)
    const search = q.get('search')
    if (search) this.setState({ search })

    this.loadCameras()
    this.interval = window.setInterval(this.loadCameras, 60000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadCameras = () => {
    RTMIP.cameras()
      .then(this.setCameras)
      .catch((err) => this.setState({ error: err.message, loaded: true }))
  }

  setCameras = (cameras: Camera[]) => {
    const { filterValues } = this.state

    const sectors = {} as Record<string, any>
    const types = {} as Record<string, any>

    cameras?.forEach((cam) => {
      cam.started_at = moment(cam.started_at)

      // eslint-disable-next-line
      if (cam.desc) this.state.cols.desc = true
      // eslint-disable-next-line
      if (cam.sector) {
        this.state.cols.sector = true
        sectors[cam.sector] = true
      }
      types[cam.type] = true
    })

    filterValues.sectors = Object.keys(sectors)
      .sort()
      .map((s) => ({ name: s } as ShortModel))
    filterValues.types = Object.keys(types)
      .sort()
      .map((s) => ({ name: s } as ShortModel))

    this.setState(
      { cameras: cameras || [], filterValues, loaded: true },
      () => {
        this.loadSchemes()
        this.loadActivity(cameras)
      }
    )
  }

  loadSchemes = () => {
    RTMIP.schemes().then((schemes) => {
      const { cameras, filterValues } = this.state

      cameras?.forEach((cam) => {
        cam.schemes = schemes?.filter((scm) => cam.schemes_id?.includes(scm.id))
        // eslint-disable-next-line
        if (cam.schemes?.length) this.state.cols.analytics = true
      })

      filterValues.schemes = schemes || []

      this.setState({ filterValues })
    })
  }

  loadActivity = (cameras?: Camera[]) => {
    const id = (cameras || this.state.cameras || [])
      .filter((cam) => cam.enabled)
      .map((s) => s.id)
    if (!id?.length) return

    RTMIP.loadActivityMany('cameras', id)
      .then(this.setActivity)
      .catch(console.error)
  }

  setActivity = (activity: Record<number, ActivityData>) => {
    this.setState({ activity })
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })

    // set search to the url
    let url = ''
    if (search) url = '?search=' + search
    else url = window.location.pathname

    window.history.replaceState('', 'cameras', url)
  }

  handleSelect = (cam: Camera) => {
    const { selected } = this.state
    selected[cam.id] ? delete selected[cam.id] : (selected[cam.id] = cam)

    this.setState({ selected })
  }

  handleSelectAll = (checked: boolean, cameras: Camera[]) => {
    const selected = {} as Record<number, Camera>
    if (checked) cameras.forEach((cam) => (selected[cam.id] = cam))

    this.setState({ selected })
  }

  handleFilter = (val: any, key: string) => {
    const { filter } = this.state
    if (!val) delete filter[key]
    else Object.assign(filter, { [key]: val })

    this.setState({ filter })
  }

  handleSort = (col: string, type: string) => {
    const { sort } = this.state
    Object.assign(sort, { col, type })
    this.setState({ sort })
  }

  perfomDelete = () => {
    let { cameras, selected } = this.state

    Object.values(selected).forEach((cam: Camera) => {
      RTMIP.deleteCamera(cam.id).catch(alert)
    })

    cameras = cameras.filter((cam: Camera) => {
      return selected[cam.id] === undefined
    })

    this.setState({ cameras: cameras, selected: {} })
  }

  toggleCamera = (cam: Camera, v: any) => {
    const { t } = this.props

    cam.enabled = !cam.enabled
    this.setState({})

    RTMIP.toggleCamera(cam.id)
      .then((resp: { status: string }) => {
        cam.status = resp.status
        if (resp.status === STATUS.DISABLED) {
          cam.enabled = false
        }
        // if (resp.status === STATUS.ACTIVE) {
        //   cam.enabled = cam.active = true
        // }
        if (resp.status === STATUS.ERRORS) {
          if (!cam.errors || cam.errors.length === 0) {
            console.warn('status is error but errors not exists')
            cam.errors = [t('errors.unexpected_error')]
          }
        }
        if (resp.status === STATUS.INIT) {
          cam.enabled = true
        }

        this.setState({})
      })
      .catch((err) => {
        cam.enabled = !cam.enabled
        this.setState({})
        alert(err)
      })
  }

  getData(): Camera[] {
    const { search, filter } = this.state

    let cameras = this.state.cameras || []

    if (search) {
      const s = search.toLocaleLowerCase()
      cameras = cameras.filter((cam: Camera) => {
        return (
          cam.name.toLocaleLowerCase().includes(s) ||
          cam.desc.toLocaleLowerCase().includes(s) ||
          cam.sector.toLocaleLowerCase().includes(s) ||
          cam.addr.toLocaleLowerCase().includes(s) ||
          cam.type.toLowerCase() === s ||
          cam.schemes?.some((scm) => scm.name.toLocaleLowerCase().includes(s))
        )
      })
    }

    if (filter.sectors?.length) {
      cameras = cameras.filter((cam) => {
        return filter.sectors.includes(cam.sector)
      })
    }

    if (filter.schemes?.length) {
      console.log('filter:', filter.schemes)
      cameras = cameras.filter((cam) => {
        return cam.schemes_id?.some((id) => filter.schemes.includes(id))
      })
    }

    if (filter.types?.length) {
      cameras = cameras.filter((cam) => {
        return filter.types.includes(cam.type)
      })
    }

    if (filter.status?.length) {
      cameras = cameras.filter((cam) =>
        statusFilter(cam as StatusModel, filter.status)
      )
    }

    return cameras
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { filter, filterValues, loaded, error, cols, sort } = this.state
    const cameras = this.getData() || ([] as Camera[])

    const { Column, HeaderCell, Cell } = Table

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel'>
          <PageTable data={cameras} total={cameras.length} sort={sort}>
            <Column width={90}>
              <HeaderCell>
                <CheckPicker
                  className='events-filter'
                  onChange={(v) => this.handleFilter(v, 'status')}
                  value={filter.status || []}
                  data={filterValues.status}
                  labelKey='name'
                  valueKey='name'
                  cleanable={false}
                  searchable={false}
                />
                <div className='events-filter-label'>{t('status')}</div>
                {this.renderSortBtn('status')}
              </HeaderCell>
              <Cell dataKey='status' align='center'>
                {(cam: any) => (
                  <Toggle
                    key={`toggle_${cam.id}`}
                    checked={cam.enabled}
                    data-active={cam.status === 'active'}
                    data-errors={hasErrors(cam.errors)}
                    onChange={(v) => this.toggleCamera(cam, v)}
                  />
                )}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>
                {t('name')}
                {this.renderSortBtn('name')}
              </HeaderCell>
              <Cell dataKey='name'>
                {(cam: any) => (
                  <Whisper
                    placement='autoVertical'
                    speaker={<Tooltip>{cam.name}</Tooltip>}>
                    {cam.enabled ? (
                      <Link to={`${ROUTES.livestream}?camera=${cam.id}`}>
                        {cam.name}
                      </Link>
                    ) : (
                      <div>{cam.name}</div>
                    )}
                  </Whisper>
                )}
              </Cell>
            </Column>

            {cols.desc && (
              <Column flexGrow={1}>
                <HeaderCell>
                  {t('desc')}
                  {this.renderSortBtn('desc')}
                </HeaderCell>
                <Cell dataKey='desc'></Cell>
              </Column>
            )}

            <Column width={120}>
              <HeaderCell>
                <CheckPicker
                  className='events-filter'
                  onChange={(v) => this.handleFilter(v, 'types')}
                  value={filter.types || []}
                  data={filterValues.types}
                  labelKey='name'
                  valueKey='name'
                  cleanable={false}
                  searchable={false}
                />
                <div className='events-filter-label'>{t('type')}</div>
                {this.renderSortBtn('type')}
              </HeaderCell>

              <Cell dataKey='type' align='center'>
                {(cam: any) => (
                  <Tag onClick={() => this.handleSearch(cam.type)}>
                    {cam.type.toUpperCase()}
                  </Tag>
                )}
              </Cell>
            </Column>

            {cols.analytics && (
              <Column flexGrow={1}>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'schemes')}
                    value={filter.schemes || []}
                    data={filterValues.schemes}
                    labelKey='name'
                    valueKey='id'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('analytics.title')}
                  </div>
                </HeaderCell>
                <Cell align='center'>{this.renderCellAnalytics}</Cell>
              </Column>
            )}

            {cols.sector && (
              <Column width={200}>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'sectors')}
                    value={filter.sectors || []}
                    data={filterValues.sectors}
                    labelKey='name'
                    valueKey='name'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>{t('sector')}</div>
                  {this.renderSortBtn('sector')}
                </HeaderCell>
                <Cell dataKey='sector' align='center'>
                  {(cam: any) =>
                    cam.sector && (
                      <Tag onClick={() => this.handleSearch(cam.sector)}>
                        {cam.sector}
                      </Tag>
                    )
                  }
                </Cell>
              </Column>
            )}

            <Column width={140}>
              <HeaderCell>
                <div>
                  {t('activity.title')}
                  <Help>{t('activity.fpm')}</Help>
                </div>
              </HeaderCell>
              <Cell>{this.renderActivityCell}</Cell>
            </Column>

            <Column fixed='right' align='right' width={180}>
              <HeaderCell className='control-table-header-cell'>
                <Checkbox
                  checked={
                    cameras?.length > 0 &&
                    Object.keys(this.state.selected).length === cameras.length
                  }
                  onChange={(v, checked) =>
                    this.handleSelectAll(checked, cameras)
                  }
                />
              </HeaderCell>
              <Cell className='actions-cell'>{this.renderCellActions}</Cell>
            </Column>
          </PageTable>

          <TableFooter>{t('cameras.add_camera')}</TableFooter>
        </Panel>
      </Content>
    )
  }

  renderCellAnalytics = (cam: any | Camera) => {
    return (
      <div className='rs-table-cell-onerow'>
        {cam.schemes?.map((scm) => (
          <Tag
            key={scm.id}
            onClick={() => this.handleSearch(scm.name)}
            color={scm.errors?.length ? 'red' : undefined}>
            {scm.name}
          </Tag>
        ))}
      </div>
    )
  }

  renderActivityCell = (cam: any | Camera) => {
    const { activity } = this.state
    if (!activity) return

    const data = activity[cam.id]
    if (!data) return

    return <ActivityChart name={cam.name} data={data} height={24} />
  }

  renderCellActions = (cam: any | Camera) => {
    const { t } = this.props
    const { selected } = this.state

    const archive = cam.params as ArchiveOpt

    return (
      <ButtonToolbar justifyContent='flex-end'>
        {cam.enabled && (
          <Whisper
            placement='top'
            trigger='hover'
            speaker={<Tooltip>{t('cameras.heatmap')}</Tooltip>}>
            <Link to={`${ROUTES.livestream}?camera=${cam.id}&heatmap`}>
              <IconButton appearance='subtle' icon={<FaBraille />} />
            </Link>
          </Whisper>
        )}

        {archive?.archive_enabled && (
          <Whisper
            placement='top'
            trigger='hover'
            speaker={<Tooltip>{t('cameras.archive')}</Tooltip>}>
            <Link to={`${ROUTES.videoarchive}?camera=${cam.name}`}>
              <IconButton appearance='subtle' icon={<FaServer />} />
            </Link>
          </Whisper>
        )}

        <Whisper
          placement='top'
          trigger='hover'
          speaker={<Tooltip>{t('edit')}</Tooltip>}>
          <Link to={path.join(window.location.pathname, cam.id.toString())}>
            <IconButton appearance='subtle' icon={<FaPen />} />
          </Link>
        </Whisper>
        <Checkbox
          checked={selected[cam.id] !== undefined || false}
          onChange={() => this.handleSelect(cam)}
        />
      </ButtonToolbar>
    )
  }

  renderSortBtn(col: string) {
    const { sort } = this.state

    let icon = <FaSort className='rs-table-cell-header-icon-sort' />
    let type = 'asc'
    if (sort.col === col) {
      if (sort.type === 'asc') {
        icon = <FaSortUp className='rs-table-cell-header-icon-sort' />
        type = 'desc'
      } else {
        icon = <FaSortDown className='rs-table-cell-header-icon-sort' />
        type = 'asc'
      }
    }

    return (
      <span
        className='rs-table-cell-header-sort-wrapper'
        onClick={() => this.handleSort(col, type)}>
        {icon}
      </span>
    )
  }

  renderHeader() {
    const { search, selected } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <ButtonToolbar>
            <Link to={`${ROUTES.status}/cameras`}>
              <IconButton icon={<FaHeartbeat />} circle />
            </Link>
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={Object.keys(selected || {}).length === 0}
              selected={selected}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }
}

export default withTranslation()(Cameras)
