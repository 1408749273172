import i18next from 'i18next'
import moment from 'moment-timezone'
import 'moment/locale/es'
import 'moment/locale/ru'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaChartBar,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaEllipsisV,
  FaHome,
  FaListAlt,
  FaMap,
  FaServer,
  FaThLarge,
  FaUser,
  FaUserMd,
} from 'react-icons/fa'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import {
  Avatar,
  Button,
  ButtonGroup,
  Content,
  Divider,
  Footer,
  Header,
  IconButton,
  Nav,
  Popover,
  Sidebar,
  Sidenav,
  Tag,
  Tooltip,
  Whisper,
} from 'rsuite'

import HealthBars from 'components/health/healthbar'
import { IMAGE } from 'components/players'
import CONFIG from 'config'
import { alert } from 'content'
import ROUTES from 'routes'
import RTMIP, { Page, User, checkAccess } from 'rtmip'
import './menubar.less'

interface Props extends WithTranslation, RouteComponentProps {
  user: User
  expand: boolean
  onToggle: () => void
  setUser: (user?: User) => void
}

interface State {
  pathname: string
  sectors: string[]
  pages: Page[]
  lang: string
  redirect: string
  exceptions: number
}

class MenuBar extends React.Component<Props, State> {
  state = {
    pathname: window.location.pathname,
    sectors: [] as Array<string>,
    pages: [] as Page[],
    lang: i18next.language,
    redirect: '',
    exceptions: 0,
  } as State

  componentDidMount() {
    moment.locale(i18next.language)

    this.loadSectors()
    this.loadPages()
    this.loadExceptions()
  }

  componentDidUpdate() {
    if (this.state.pathname !== window.location.pathname) {
      this.setState({ pathname: window.location.pathname })
    }
  }

  loadSectors = () => {
    if (checkAccess(this.props.user, '/cameras')) {
      RTMIP.sectors().then((sectors) =>
        this.setState({ sectors: sectors || [] })
      )
    }
  }

  loadPages = () => {
    RTMIP.pages()
      .then((pages) => this.setState({ pages: pages || [] }))
      .catch(alert)
  }

  loadExceptions = () => {
    RTMIP.countExceptions()
      .then((count) => this.setState({ exceptions: count }))
      .catch(console.warn)
  }

  changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang)
    moment.locale(lang)
    this.setState({ lang: lang })
  }

  logout = () => {
    RTMIP.logout()
      .then(() => {
        this.props.setUser()
        window.location.assign('/auth')
      })
      .catch((err) => {
        alert(err)
        this.props.setUser()
        window.location.assign('/auth')
      })
  }

  multiCheckAccess = (path: string[], level: number = 0): boolean => {
    return path.some((p: string) => checkAccess(this.props.user, p, level))
  }

  //
  // render
  //

  render() {
    const { t, user, expand, onToggle } = this.props
    const { pathname, sectors, pages, exceptions, redirect } = this.state
    const openKeys = ['/' + pathname.split('/')[1]]

    if (redirect) return <Redirect to={redirect} />

    return (
      <Sidebar className='menu' data-expand={expand} width='auto' collapsible>
        <Header className='menu-block'>
          <img
            className='menu-logo'
            alt='logo'
            src={RTMIP.url(
              `/assets/img/${expand ? 'header-logo' : 'logo'}.png`
            )}
          />
          <Whisper
            placement='right'
            trigger='hover'
            speaker={<Tooltip>{t('tooltip_toggle_menubar')}</Tooltip>}>
            <IconButton
              className='togglebar-btn'
              icon={expand ? <FaChevronLeft /> : <FaChevronRight />}
              onClick={onToggle}
              size='xs'
              circle
            />
          </Whisper>
        </Header>

        <PerfectScrollbar>
          <Content className='menu-content'>
            <Sidenav
              defaultOpenKeys={openKeys}
              appearance='subtle'
              expanded={expand}>
              <Sidenav.Body>
                <Nav vertical>
                  <MenuItem path={ROUTES.dashboard} icon={<FaHome />} exact>
                    {t('dashboard.title')}
                  </MenuItem>

                  {checkAccess(user, '/cameras') &&
                    (sectors && sectors.length ? (
                      <Nav.Menu
                        trigger={['click', 'hover']}
                        eventKey='/livestream'
                        title={t('livestream.title')}
                        icon={<FaThLarge />}
                        onClick={this.loadSectors}
                        placement='rightStart'>
                        {sectors.map((sector) => (
                          <DropdownItem
                            key={sector}
                            path={`${ROUTES.livestream}/${sector}`}
                            exact>
                            {sector}
                          </DropdownItem>
                        ))}
                        <DropdownItem path={ROUTES.livestream} exact>
                          {t('livestream.all')}
                        </DropdownItem>
                      </Nav.Menu>
                    ) : (
                      <MenuItem path={ROUTES.livestream} icon={<FaThLarge />}>
                        {t('livestream.title')}
                      </MenuItem>
                    ))}

                  {pages.map((page) => {
                    if (page.menu) return null
                    return (
                      <MenuItem
                        key={page.name}
                        path={`${ROUTES.pages}/${page.name}`}
                        icon={page.icon}>
                        {page.title || page.name}
                      </MenuItem>
                    )
                  })}

                  {checkAccess(user, ROUTES.archive) && (
                    <MenuItem path={ROUTES.videoarchive} icon={<FaServer />}>
                      {t('videoarchive.title')}
                    </MenuItem>
                  )}

                  {checkAccess(user, ROUTES.reports) && (
                    <MenuItem path={ROUTES.eventsarchive} icon={<FaListAlt />}>
                      {t('eventsarchive.title')}
                    </MenuItem>
                  )}

                  {checkAccess(user, '/floormaps') && (
                    <MenuItem path={ROUTES.floormaps} icon={<FaMap />}>
                      {t('floormaps.title')}
                    </MenuItem>
                  )}

                  {checkAccess(user, ROUTES.charts) && (
                    <MenuItem path={ROUTES.charts} icon={<FaChartBar />}>
                      {t('charts.title')}
                    </MenuItem>
                  )}

                  {this.multiCheckAccess(['/profiles', '/cars', '/groups']) && (
                    <Nav.Menu
                      trigger={['click', 'hover']}
                      eventKey='/profiles'
                      title={t('profiles.title')}
                      icon={<FaUser />}
                      placement='rightStart'>
                      {checkAccess(user, '/profiles') && (
                        <DropdownItem path={ROUTES.profiles.people}>
                          {t('profiles.people.title')}
                        </DropdownItem>
                      )}
                      {checkAccess(user, '/cars') && (
                        <DropdownItem path={ROUTES.profiles.cars}>
                          {t('profiles.cars.title')}
                        </DropdownItem>
                      )}
                      {checkAccess(user, '/groups') && (
                        <DropdownItem path={ROUTES.profiles.groups}>
                          {t('profiles.groups.title')}
                        </DropdownItem>
                      )}
                      {pages.map((page) => {
                        if (page.menu !== 'profiles') return null
                        return (
                          <DropdownItem
                            key={page.name}
                            path={`${ROUTES.pages}/${page.name}`}>
                            {page.title || page.name}
                          </DropdownItem>
                        )
                      })}
                    </Nav.Menu>
                  )}

                  {this.multiCheckAccess(
                    [
                      '/cameras',
                      '/schemes',
                      '/detectors',
                      '/devices',
                      '/scripts',
                      '/notifications',
                      '/users',
                      '/settings/themes',
                      '/license',
                      '/packages',
                      '/plugins',
                      '/themes',
                    ],
                    1
                  ) && (
                    <Nav.Menu
                      trigger={['click', 'hover']}
                      eventKey='/settings'
                      title={t('settings.title')}
                      icon={<FaCog />}
                      placement='rightStart'>
                      {checkAccess(user, '/cameras', 1) && (
                        <DropdownItem path={ROUTES.settings.cameras}>
                          {t('cameras.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/schemes', 1) && (
                        <DropdownItem path={ROUTES.settings.analytics}>
                          {t('analytics.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/detectors', 1) && (
                        <DropdownItem path={ROUTES.settings.detectors}>
                          {t('detectors.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/scripts', 1) && (
                        <DropdownItem path={ROUTES.settings.scripts}>
                          {t('scripts.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/notifications', 1) && (
                        <DropdownItem path={ROUTES.settings.notifications}>
                          {t('notifications.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/devices', 1) && (
                        <DropdownItem path={ROUTES.settings.devices}>
                          {t('devices.title')}
                        </DropdownItem>
                      )}

                      {exceptions > 0 && checkAccess(user, '/schemes', 1) && (
                        <DropdownItem path={ROUTES.settings.exceptions}>
                          {t('analytics.exceptions')} <Tag>{exceptions}</Tag>
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/users', 1) && (
                        <DropdownItem path={ROUTES.settings.users}>
                          {t('users.title')}
                        </DropdownItem>
                      )}

                      {pages.map((page) => {
                        if (page.menu !== 'settings') return null
                        return (
                          <DropdownItem
                            key={page.name}
                            path={`${ROUTES.pages}/${page.name}`}>
                            {page.title || page.name}
                          </DropdownItem>
                        )
                      })}
                      {checkAccess(user, '/themes', 1) && (
                        <DropdownItem path={ROUTES.settings.themes}>
                          {t('themes.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/packages', 1) && (
                        <DropdownItem path={ROUTES.settings.packages}>
                          {t('packages.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/plugins', 0) && (
                        <DropdownItem path={ROUTES.settings.plugins}>
                          {t('plugins.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/journal', 0) && (
                        <DropdownItem path={ROUTES.settings.journal}>
                          {t('journal.title')}
                        </DropdownItem>
                      )}

                      {checkAccess(user, '/license', 1) && (
                        <DropdownItem path={ROUTES.settings.license}>
                          {t('license.title')}
                        </DropdownItem>
                      )}
                    </Nav.Menu>
                  )}
                </Nav>
              </Sidenav.Body>
            </Sidenav>

            <HealthBars expand={expand} />
          </Content>
        </PerfectScrollbar>

        {this.renderFooter()}
      </Sidebar>
    )
  }

  renderFooter() {
    const { user } = this.props
    return (
      <Footer className='menu-footer'>
        <div className='menu-block menu-user'>
          <Avatar circle>{this.renderUserPhoto()}</Avatar>
          <span className='menu-footer-username'>
            {user.name || user.username}
          </span>
          <Whisper
            placement='top'
            trigger='click'
            speaker={this.renderUserMenu()}>
            <IconButton appearance='subtle' icon={<FaEllipsisV />} />
          </Whisper>
        </div>
      </Footer>
    )
  }

  renderUserPhoto() {
    const { user } = this.props

    if (user.photo)
      return <IMAGE src={user.photo} width={40} height={40} background />

    if (user.superadmin || user.username === 'neirolis') return <FaUserMd />

    return <FaUser />
  }

  renderUserMenu() {
    const { t, user } = this.props

    return (
      <Popover>
        <ButtonGroup justified>
          {CONFIG.LANGUAGES.map((lang: string) => {
            return (
              <Button
                key={lang}
                onClick={() => this.changeLanguage(lang)}
                style={{ width: 40 }}
                active={lang === i18next.language}>
                {lang.toUpperCase()}
              </Button>
            )
          })}
        </ButtonGroup>
        <Divider />

        {user.id > 0 && (
          <Link to={`${ROUTES.settings.users}/${user.id}`}>
            <Button appearance='subtle' block>
              {t('users.edit')}
            </Button>
          </Link>
        )}

        <Link to={ROUTES.changelog}>
          <Button appearance='subtle' block>
            {t('changelog')}
          </Button>
        </Link>

        <a href='https://rtmip.wiki' rel='noreferrer' target='_blank'>
          <Button appearance='subtle' block>
            {t('documentation')}
          </Button>
        </a>

        {i18next.language == 'ru' && (
          <a
            href={RTMIP.url(`/User Agreement.pdf`)}
            rel='noreferrer'
            target='_blank'>
            <Button appearance='subtle' block>
              {t('user_agreement')}
            </Button>
          </a>
        )}

        <Button appearance='subtle' onClick={() => this.logout()} block>
          {t('logout')}
        </Button>
      </Popover>
    )
  }
}

interface MenuItemProps {
  path: string
  exact?: boolean
  icon?: any

  children?: any
}

class MenuItem extends React.Component<MenuItemProps> {
  render() {
    const { pathname } = window.location
    const { path, children, exact } = this.props

    let icon = this.props.icon
    if (icon && typeof icon === 'string') {
      icon = (
        <img
          className='rs-icon menuitem-imgicon'
          alt={icon}
          src={RTMIP.url(icon)}
        />
      )
    }

    return (
      <Nav.Item
        eventKey={path}
        active={exact ? pathname === path : pathname.startsWith(path)}
        as={Link}
        {...{ to: path }}
        icon={icon}>
        {children}
      </Nav.Item>
    )
  }
}

class DropdownItem extends React.Component<MenuItemProps> {
  render() {
    const { pathname } = window.location
    const { path, children, exact } = this.props

    return (
      <Nav.Item
        eventKey={path}
        active={exact ? pathname === path : pathname.startsWith(path)}
        as={Link}
        {...{ to: path }}>
        {children}
      </Nav.Item>
    )
  }
}

export default withTranslation()(withRouter(MenuBar))
