import { Resizable } from 're-resizable'
import React, { Suspense } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Button, ButtonToolbar, Container, Notification, toaster } from 'rsuite'

import Auth from 'auth/auth'
import {
  CameraForm,
  Cameras,
  LiveStream,
  VideoArchive,
} from 'components/cameras'
import { DeviceForm, Devices } from 'components/devices'
import {
  EventsArchive,
  EventsBar,
  EventsImage,
  EventsModalImage,
} from 'components/events'
import { Exceptions } from 'components/exceptions'
import { FloorMapForm, FloorMapView, FloorMaps } from 'components/floormaps'
import { LicenseForm } from 'components/license'
import { NotificationForm, Notifications } from 'components/notifications'
import Pages from 'components/pages'
import {
  CarForm,
  Cars,
  Groups,
  ProfileForm,
  Profiles,
} from 'components/profiles'
import Themes, { setTheme, themeInjection } from 'components/themes'
import { UserForm, Users } from 'components/users'
import { Spinner, setTitle } from 'content'
import MenuBar from 'menubar'
import ROUTES from 'routes'
import RTMIP, { Event, NotifyMessage, User, WS } from 'rtmip'

import { FaExclamationCircle } from 'react-icons/fa'
import { PlacementType } from 'rsuite/esm/toaster/ToastContainer'
import './app.less'
import './perfect-scrollbar.less'
import './rel.less'

const Dashboard = React.lazy(() => import('components/dashboards/dashboard'))
const Charts = React.lazy(() => import('components/charts/charts'))
const ChartForm = React.lazy(() => import('components/charts/chartform'))

const Detectors = React.lazy(() => import('components/detectors/detectors'))
const Analytics = React.lazy(() => import('components/analytics/analytics'))
const SchemeEditor = React.lazy(
  () => import('components/analytics/schemeeditor')
)
const DetectorForm = React.lazy(
  () => import('components/detectors/detectorform')
)
const Scripts = React.lazy(() => import('components/scripts/scripts'))
const ScriptForm = React.lazy(() => import('components/scripts/scriptform'))

const Packages = React.lazy(() => import('components/packages/packages'))
const Plugins = React.lazy(() => import('components/plugins/plugins'))
const Journal = React.lazy(() => import('components/journal/journal'))
const Terminal = React.lazy(() => import('components/terminal/terminal'))
const Changelog = React.lazy(() => import('components/changelog'))
const StatusPage = React.lazy(() => import('content/statuspage'))

const EventsBarDefaultWidth = 240

interface Props extends WithTranslation {}
interface State {
  init: boolean
  user: User | undefined
  path: string
  menu: boolean
  events: boolean
  eventsWidth: number

  popupEvent?: Event
  popupImage?: string
}

class App extends React.Component<Props, State> {
  state = {
    path: window.location.pathname,
    menu: true,
    events: true,
    eventsWidth: EventsBarDefaultWidth,
  } as State

  wsAlerts?: WS
  wsPopups?: WS

  constructor(props: any) {
    super(props)
    setTheme()

    const menubar = window.localStorage.getItem('menubar')
    if (menubar !== null) this.state.menu = menubar === 'true'

    const eventsbar = window.localStorage.getItem('eventsbar')
    if (eventsbar !== null) this.state.events = eventsbar === 'true'

    const eventsWidth = window.localStorage.getItem('eventsbar-width')
    if (eventsWidth !== null) this.state.eventsWidth = parseInt(eventsWidth)
  }

  componentDidMount() {
    setTitle('dashboard.title')

    RTMIP.user()
      .then(this.setUser)
      .catch((err) => {
        if (!err) return
        console.error(err.message)
        this.setState({ init: true })
        if (err.message.includes('expired')) {
          RTMIP.logout()
        }
      })
    window.addEventListener('resize', this.onWindowResize)
    this.onWindowResize()
  }

  onWindowResize = () => {
    const menubar = window.localStorage.getItem('menubar')
    if (menubar === null) {
      if (window.innerWidth < 1200 && this.state.menu) {
        this.setState({ menu: false })
      } else if (window.innerWidth >= 1200 && !this.state.menu) {
        this.setState({ menu: true })
      }
    }

    const eventsbar = window.localStorage.getItem('eventsbar')
    if (eventsbar === null) {
      if (window.innerWidth < 800 && this.state.menu) {
        this.setState({ events: false })
      } else if (window.innerWidth >= 800 && !this.state.menu) {
        this.setState({ events: true })
      }
    }
  }

  componentDidUpdate() {
    if (this.state.path !== window.location.pathname) {
      this.setState({ path: window.location.pathname })
    }
  }

  componentWillUnmount() {
    this.wsAlerts?.unsubscribe()
    this.wsAlerts?.close()

    this.wsPopups?.unsubscribe()
    this.wsPopups?.close()
  }

  setUser = (user: User | undefined) => {
    this.setState({ user, init: true })

    if (user) {
      this.wsAlerts = RTMIP.ws()
      this.wsAlerts.alerts(this.eventAlerts).then((ws) => ws.subscribe())

      this.wsPopups = RTMIP.ws()
      this.wsPopups.popups(this.popupNotifications).then((ws) => ws.subscribe())

      themeInjection()

      RTMIP.time()
    }
  }

  eventAlerts = (e: Event) => {
    const { t } = this.props

    toaster.push(
      <Notification
        header={
          <div className='event-alert-title'>
            <FaExclamationCircle />
            {`${e.camera}: ${e.status || t('eventsfeed.alert')}`}
          </div>
        }>
        {e.frame && <EventsImage event={e} imgSrc={e.frame} />}
      </Notification>,
      {
        placement: 'bottomEnd',
        container: document.getElementById('root')!,
      }
    )
  }

  popupNotifications = (msg: NotifyMessage) => {
    const { t } = this.props

    let key = undefined as any
    key = toaster.push(
      <Notification header={msg.title} closable>
        <div>
          <p>{msg.text}</p>
          {msg.image?.length > 0 && (
            <div
              onClick={() => {
                this.handleOpenPopup(msg)
                if (key instanceof Promise) {
                  key.then(toaster.remove)
                } else {
                  toaster.remove(key)
                }
              }}
              style={{ cursor: msg.event ? 'pointer' : 'default' }}>
              <EventsImage
                event={msg.event}
                imgSrc={'data:image/jpeg;base64, ' + msg.image}
                withButtons={false}
              />
            </div>
          )}
          {msg.timeout <= 0 && (
            <ButtonToolbar
              className='footer'
              justifyContent='center'
              onClick={() => {
                if (key instanceof Promise) {
                  key.then(toaster.remove)
                } else {
                  toaster.remove(key)
                }
              }}>
              <Button>{t('close')}</Button>
            </ButtonToolbar>
          )}
        </div>
      </Notification>,
      {
        placement: msg.position ? (msg.position as PlacementType) : 'bottomEnd',
        duration: msg.timeout || 5000,
        container: document.getElementById('root')!,
      }
    )
  }

  handleOpenPopup = (msg?: NotifyMessage) => {
    this.setState({
      popupEvent: msg?.event,
      popupImage: msg ? 'data:image/jpeg;base64, ' + msg.image : undefined,
    })
  }

  toggleMenu = () => {
    window.localStorage.setItem('menubar', (!this.state.menu).toString())
    this.setState({ menu: !this.state.menu })
  }

  toggleEvents = () => {
    window.localStorage.setItem('eventsbar', (!this.state.events).toString())
    this.setState({ events: !this.state.events })
  }

  resizeEvents = (e: MouseEvent | TouchEvent, dir: any, ref: any, d: any) => {
    let width = EventsBarDefaultWidth + d.width
    width = Math.max(EventsBarDefaultWidth, width)

    window.localStorage.setItem('eventsbar-width', width.toString())
    this.setState({ eventsWidth: width })
  }

  getContentRightMargin = (): number => {
    const { events, eventsWidth } = this.state

    if (!events) return 0
    return eventsWidth
  }

  //
  //
  //

  render() {
    const {
      init,
      user,
      path,
      menu,
      events,
      eventsWidth,
      popupEvent,
      popupImage,
    } = this.state

    if (!init) return <Spinner className='spinner-screen' />
    if (!user || path.startsWith('/auth')) return this.renderAuth()

    return (
      <Router>
        <Container>
          <MenuBar
            expand={menu}
            user={user}
            onToggle={this.toggleMenu}
            setUser={this.setUser}
          />

          {popupEvent && popupImage && (
            <EventsModalImage
              event={popupEvent}
              imgSrc={popupImage}
              onHide={this.handleOpenPopup}
            />
          )}

          <Resizable
            className='eventsbar'
            data-expand={events}
            size={{ width: eventsWidth, height: '100vh' }}
            defaultSize={{ width: EventsBarDefaultWidth, height: '100vh' }}
            enable={{
              top: false,
              right: false,
              bottom: false,
              left: events,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            onResizeStop={this.resizeEvents}>
            <EventsBar
              expand={events}
              onToggle={this.toggleEvents}
              user={user}
            />
          </Resizable>

          <Scrollbars
            className='content'
            style={{
              height: '100vh',
              marginRight: this.getContentRightMargin(),
            }}
            autoHide>
            <Switch>
              <Route path='/auth' />

              <Suspense fallback={<Spinner className='spinner-screen' />}>
                <Route exact path={ROUTES.dashboard} component={Dashboard} />
                <Route exact path={ROUTES.dashboard_id} component={Dashboard} />

                <Route exact path={ROUTES.livestream} component={LiveStream} />
                <Route
                  exact
                  path={ROUTES.livestream_sector}
                  component={LiveStream}
                />

                <Route
                  exact
                  path={ROUTES.videoarchive}
                  component={VideoArchive}
                />

                <Route
                  exact
                  path={ROUTES.eventsarchive}
                  component={EventsArchive}
                />

                {/* charts */}
                <Route exact path={ROUTES.charts} component={Charts} />
                <Route exact path={ROUTES.charts_id} component={ChartForm} />

                {/* floormaps */}
                <Route exact path={ROUTES.floormaps} component={FloorMaps} />
                <Route
                  exact
                  path={ROUTES.floormaps_id_edit}
                  component={FloorMapForm}
                />
                <Route
                  exact
                  path={ROUTES.floormaps_id}
                  component={FloorMapView}
                />

                {/* profiles */}
                <Route
                  exact
                  path={ROUTES.profiles.people}
                  component={Profiles}
                />
                <Route
                  exact
                  path={ROUTES.profiles.people_id}
                  component={ProfileForm}
                />
                <Route exact path={ROUTES.profiles.cars} component={Cars} />
                <Route
                  exact
                  path={ROUTES.profiles.cars_id}
                  component={CarForm}
                />
                <Route exact path={ROUTES.profiles.groups} component={Groups} />

                {/* cameras */}
                <Route
                  exact
                  path={ROUTES.settings.cameras}
                  component={Cameras}
                />
                <Route
                  exact
                  path={ROUTES.settings.cameras_id}
                  component={CameraForm}
                />

                {/* analytics */}
                <Route
                  exact
                  path={ROUTES.settings.analytics}
                  component={Analytics}
                />
                <Route
                  exact
                  path={ROUTES.settings.analytics_id}
                  component={SchemeEditor}
                />

                {/* detectors */}
                <Route
                  exact
                  path={ROUTES.settings.detectors}
                  component={Detectors}
                />
                <Route
                  exact
                  path={ROUTES.settings.detectors_id}
                  component={DetectorForm}
                />

                {/* devices */}
                <Route
                  exact
                  path={ROUTES.settings.devices}
                  component={Devices}
                />
                <Route
                  exact
                  path={ROUTES.settings.devices_id}
                  component={DeviceForm}
                />

                {/* notifications */}
                <Route
                  exact
                  path={ROUTES.settings.notifications}
                  component={Notifications}
                />
                <Route
                  exact
                  path={ROUTES.settings.notifications_id}
                  component={NotificationForm}
                />

                {/* scripts */}
                <Route
                  exact
                  path={ROUTES.settings.scripts}
                  component={Scripts}
                />
                <Route
                  exact
                  path={ROUTES.settings.scripts_id}
                  component={ScriptForm}
                />

                <Route
                  exact
                  path={ROUTES.settings.exceptions}
                  component={Exceptions}
                />

                {/* users */}
                <Route exact path={ROUTES.settings.users} component={Users} />
                <Route
                  exact
                  path={ROUTES.settings.users_id}
                  component={UserForm}
                />

                {/* themes */}
                <Route exact path={ROUTES.settings.themes} component={Themes} />

                {/* license */}
                <Route
                  exact
                  path={ROUTES.settings.license}
                  component={LicenseForm}
                />

                {/* packages */}
                <Route
                  exact
                  path={ROUTES.settings.packages}
                  component={Packages}
                />
                <Route
                  exact
                  path={ROUTES.settings.packages_name}
                  component={Packages}
                />

                {/* plugins */}
                <Route
                  exact
                  path={ROUTES.settings.plugins}
                  component={Plugins}
                />

                {/* journal */}
                <Route
                  exact
                  path={ROUTES.settings.journal}
                  component={Journal}
                />
                <Route
                  exact
                  path={ROUTES.settings.journal_section}
                  component={Journal}
                />

                <Route
                  exact
                  path={ROUTES.status_section}
                  component={StatusPage}
                />

                <Route exact path={ROUTES.terminal} component={Terminal} />
                <Route exact path={ROUTES.changelog} component={Changelog} />

                <Route exact path={ROUTES.pages_name} component={Pages} />
              </Suspense>
            </Switch>
          </Scrollbars>
        </Container>
      </Router>
    )
  }

  renderAuth() {
    return (
      <Router>
        <Route
          path='/'
          render={(props) => <Auth {...props} setUser={this.setUser} />}
        />
      </Router>
    )
  }
}

export default withTranslation()(App)
